
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-class';
import { titleCase, mergeClasses, isMasked } from '@/utils';
import SubSection from '@/components/shared/SubSection.vue';
import LoadingDonorSummaryCard from '@/components/shared/LoadingDonorSummaryCard.vue';
import { DeceasedDonor, DeceasedDonorOrgan, DeceasedDonorAllocationSummary } from '@/store/deceasedDonors/types';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Organ, AllDispositionStatus } from '@/store/lookups/types';
import DiscontinueOrgansModal from '@/components/allocations/_DiscontinueOrgansModal.vue';
import { GenericCodeValue } from '@/store/types';
import { Hospital } from '@/store/hospitals/types';
import { Allocations, OfferOutcomeContext } from '@/store/allocations/types';
import OrganIcon from '@/components/shared/OrganIcon.vue';
import { ClassObject } from '@/types';
import CtrLogModal from '@/components/shared/CtrLogModal.vue';
import { discontinueAllocationLookupType } from '@/store/lookups/types';

interface DonorSummaryForm {
  system_id?: number;
  deceased_donor_id?: number;
  national_donor_id?: string;
  donor_name?: string;
  sex?: string;
  age?: number;
  abo?: string|null;
  donor_type?: string;
  height?: number;
  weight?: number
  cause_of_death?: string;
  region?: string;
  serology?: string;
  referral_hospital?: string;
  exceptional_distribution?: string;
}

@Component({
  components: {
    OrganIcon,
    SubSection,
    CtrLogModal,
    DiscontinueOrgansModal,
    LoadingDonorSummaryCard,
  }
})
export default class DonorSummary extends mixins(DateUtilsMixin) {
  @Prop({ default: null }) openNowModal!: any; // NOW Modal Ref

  // State
  @State(state => state.lookups.organ) organLookup!: Organ;
  @State(state => state.deceasedDonors.selected) donor!: DeceasedDonor;
  @State(state => state.pageState.currentPage.donorSummary) editState!: DonorSummaryForm;
  @State(state => state.allocations.isLoadingAllocation) isLoadingAllocation!: boolean;
  @State(state => state.allocations.isLoadingAllocations) isLoadingAllocations!: boolean;
  @State(state => state.allocations.isDiscontinuingAllAllocations) isDiscontinuingAllAllocations!: boolean;

  // Getters
  @Getter('clientId', { namespace: 'deceasedDonors' }) private clientId!: string|undefined;
  @Getter('deceasedDonorDisplayName', { namespace: 'deceasedDonors' }) deceasedDonorDisplayName!: string;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;
  @Getter('getHospitalById', { namespace: 'hospitals' }) hospital!: (hospitalCode?: string) => Hospital;
  @Getter('getHospitalAbbreviation', { namespace: 'hospitals' }) getHospitalAbbreviation!: (hospitalCode?: string|null) => string|null;
  @Getter('organName', { namespace: 'lookups' }) organNameLookup!: (organCode?: number) => string;
  @Getter('selectedDonorConsentedOrganList', { namespace: 'deceasedDonors'}) private selectedDonorConsentedOrganList!: DeceasedDonorAllocationSummary[];
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('isTransplantCoordinator', { namespace: 'users' }) private isTransplantCoordinator!: boolean;
  @Getter('getDonorAge', { namespace: 'deceasedDonors' }) getDonorAge!: number|undefined;
  @Getter('canAccessNowList', { namespace: 'users' }) private canAccessNowList!: boolean;
  @Getter('donorCanAccessCtrLog', { namespace: 'users' }) private donorCanAccessCtrLog!: boolean;

  @Prop({ default: '' }) inputClass!: string; // Class string for list

  public lookupsToLoad = [ 'cause_of_death_donor', 'discontinue_allocation_categories_reasons' ];

  public hospitalsToLoad = [ 'all' ];

  private isFinishedLoadingEvents = false;
  private isLoaded = false;

  /**
   * Get hash for donation information section if allowed to see the section
   *
   * @returns {string} return hash to section, otherwise empty
   */
  get allowJumpToDonationInformation(): string {
    const apiAllowed = this.checkAllowed("/donors/:id", "GET");
    return apiAllowed ? "#donation-information" : "";
  }

  /**
   * Get a boolean represenation of if we have any active allocations
   *
   * @returns {boolean} true if there are active allocations
   */
  get showDiscontinue(): boolean {
    // TODO: replace this - check API for active allocations (not implemented yet)
    const apiAllowed = this.checkAllowed("/donors/:donor_id/organs/:organ_id/allocations/:allocation_id/offers/discontinue_offers", "POST");
    return this.selectedDonorConsentedOrganList.length > 0 && apiAllowed;
  }

   //Getter - Selected organ shown as active
  get listItemClass(){
    return (consentedOrgan: any): ClassObject => {
      const option = this.$route.params.option;
      const organCode = this.$route.params.organ_code;
      const consentedOrganRoute = consentedOrgan.route.params.option;
      const consentedOrganCode = consentedOrgan.organ_code;
      let isActive : any;

      if(consentedOrgan.route.params.option)
      {
        isActive = consentedOrgan.display_name.toLowerCase().includes(option) && organCode == consentedOrganCode.toString();
      } else {
        isActive = organCode == consentedOrganCode.toString();
      }
      //Define dynamic class based on the selected organ
      const conditionalClass = {
        'active' : isActive
      };
      return conditionalClass;

    };
  }

  /**
   * Initializes the Donor Summary state after the subsection has finished loading lookups and hospitals
   *
   * Emits a loaded event to notify its page layout that loading of this card section has completed
   *
   * @emits loaded
   */
  private checkIfLoadingComplete(): void {
    if (this.isFinishedLoadingEvents) {
      this.isLoaded = true;
      this.initializeForm();
      this.$emit('loaded', 'donorSummary');
    }
  }

  @Watch('donor', { immediate: true })
  private onDeceasedDonorChanged(): void {
    this.initializeForm();
  }

  public loaded(): void {
    this.isFinishedLoadingEvents = true;

    this.checkIfLoadingComplete();
  }

  // Private methods
  public initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'donorSummary',
      value: this.extractDonorSummaryForm(this.donor)
    });
  }

  // Format date-only field for display
  public formatDate(dateValue: string): string|undefined {
    if (!isNaN(Date.parse(dateValue))) {
      return this.parseDisplayDateUi(dateValue);
    } else {
      return dateValue;
    }
  }

  // Format combined date/time for display
  public formatDateTime(dateTimeValue: string): string|undefined {
    if (!isNaN(Date.parse(dateTimeValue))) {
      return this.parseFormattedDateTimeUi(dateTimeValue);
    } else {
      return dateTimeValue;
    }
  }

  public toggleClass(organ_code: string){
    let organCode = this.$route.params.organ_code;
    return organ_code == organCode? true : false;
  }

  public extractDonorSummaryForm(donor?: DeceasedDonor): DonorSummaryForm {
    // Return empty object if there is no data document
    if (!donor) {
      return {};
    }
    // Sanitize nested objects, so their parameters can easily be read as 'undefined' instead of raising type errors
    const patientProfile = donor.patient_profile || {};
    const referral = donor.referral || {};
    const blood = donor.blood || {};
    const donorMeasurements = donor.measurements || [];
    const death = donor.death || {};
    const indicators = donor.indicators || {};

    const addresses = patientProfile.addresses || {};
    const measurement = donorMeasurements.length > 0 ? donorMeasurements[donorMeasurements.length-1] : {};
    const hospitalId = referral.referring_hospital_id ? referral.referring_hospital_id.$oid : undefined;
    const referral_hospital = isMasked(hospitalId) ? hospitalId : this.getHospitalAbbreviation(hospitalId);

    // Return parameters extracted from data document based on structure of form state interface
    return {
      system_id: donor.client_id,
      deceased_donor_id: donor.deceased_donor_id,
      national_donor_id: patientProfile && patientProfile.ctr ? patientProfile.ctr!.national_donor_id : undefined,
      donor_name: patientProfile.first_name && patientProfile.last_name,
      sex: patientProfile.sex || undefined,
      age: patientProfile.age,
      abo: blood.sub_type ? blood.sub_type : blood.type,
      donor_type: death?.neurological_death ? "NDD" : "DCD",
      height: measurement.height,
      weight: measurement.weight,
      cause_of_death: this.lookupValue(death.cause_of_death_code?.toString(), 'cause_of_death_donor'),
      referral_hospital: referral_hospital || undefined,
      exceptional_distribution: indicators?.exceptional_distribution ? this.$t('yes').toString() : this.$t('no').toString()
    };
  }

  // discontinue organs
  private discontinueOrgans(): void {
    // open modal
    const discontinueDonorModal = this.$refs.discontinueDonorModal as DiscontinueOrgansModal;
    discontinueDonorModal.initialize();
  }

  // Initialize and open the CTR Log Modal
  private openCtrModal(): void {
    const ctrLogModal = this.$refs.ctrLogModal as CtrLogModal;
    ctrLogModal.initialize('deceasedDonors', (this.clientId || ''));
  }

  // Offer outcome notification events bubble up to the view
  private displayOutcomeNotification(context: OfferOutcomeContext) {
    this.$emit('display-outcome-notification', context);
  }
}
