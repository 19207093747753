
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { AllocationErrorsMixin } from "@/mixins/allocation-errors-mixin";
import { AllocationUtilsMixin } from "@/mixins/allocation-utils-mixin";
import { TableConfig } from '@/types';
import { Getter, State } from 'vuex-class';
import { SortedTable, SortLink } from 'vue-sorted-table';
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Organ, OrganSpecification } from '@/store/lookups/types';
import { LivingDonor } from '@/store/livingDonors/types';
import { LivingAllocationResponse, LivingAllocationRecipient, LivingAllocationResponseAction, 
  LivingAllocationOfferResponseCodeValues, LivingAllocationOfferTypeValues, LivingAllocation, 
  LivingAllocationOffer, LivingAllocationOfferRecipient, LivingAllocationStateValues, RegistrationType } from '@/store/livingAllocations/types';
import { GenericCodeValue, ObjectId } from '@/store/types';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import OfferIcon from '@/components/livingAllocations/offers/OfferIcon.vue';
import OfferResponseAccept from '@/components/livingAllocations/offers/OfferResponseAccept.vue';
import OfferResponseDecline from '@/components/livingAllocations/offers/OfferResponseDecline.vue';
import { isMasked } from '@/utils';
import CompareModal from '@/components/livingDonors/CompareModal.vue';
import { OrganCodeValue } from '@/store/lookups/types';

interface PageState {
  rowsAll: LivingAllocationResponse[];
  rows: LivingAllocationResponse[];
  editedRows: LivingAllocationResponse[];
  selectAllMatchingRows: boolean;
  programFilterValue: string|null;
}

const NO_RESPONSIBLE_PHYSICIAN = '-';

@Component({
  components: {
    OfferIcon,
    SubSection,
    SelectInput,
    SortedTable,
    CompareModal,
    CheckboxInput,
    OfferResponseAccept,
    OfferResponseDecline,
  }
})
export default class AllocationOfferResponse extends mixins(DateUtilsMixin, AllocationErrorsMixin, AllocationUtilsMixin) {
  @State(state => state.lookups.organ) organLookup!: Organ[];
  @State(state => state.pageState.currentPage.livingAllocationOfferResponses) editState!: PageState;
  @State(state => state.livingDonors.selectedLivingDonor) private livingDonor!: LivingDonor;
  @State(state => state.livingAllocations.isLoadingAllocation) private isLoadingAllocation!: boolean;
  @State(state => state.livingAllocations.isRespondingOffer) private isRespondingOffer!: boolean;
  @State(state => state.livingAllocations.isDecliningMultiple) private isDecliningMultiple!: boolean;

  @Getter('clientId', { namespace: 'livingDonors' }) private clientId!: string|undefined;
  @Getter('selectedAllocation', { namespace: 'livingAllocations' }) private allocation!: LivingAllocation;
  @Getter('allPrimaryBackupOffers', { namespace: 'livingAllocations' }) private allPrimaryBackupOffers!: LivingAllocationRecipient[];
  @Getter('offerResponses', { namespace: 'lookups' }) private offerResponses!: GenericCodeValue[];
  @Getter('responseOptions', { namespace: 'livingAllocations' }) private responseOptions!: (offer: LivingAllocationResponse, offerResponses: GenericCodeValue[]) => GenericCodeValue[];
  @Getter('reasonCategoryOptions', { namespace: 'livingAllocations' }) private reasonCategoryOptions!: (offer: LivingAllocationResponse, offerResponses: GenericCodeValue[], organCode: string) => GenericCodeValue[];
  @Getter('reasonOptions', { namespace: 'livingAllocations' }) private reasonOptions!: (offer: LivingAllocationResponse, offerResponses: GenericCodeValue[], organCode: string) => GenericCodeValue[];
  @Getter('disableResponseOptions', { namespace: 'livingAllocations' }) private disableResponseOptions!: (offer: LivingAllocationResponse) => boolean;
  @Getter('disableResponseCategoryOptions', { namespace: 'livingAllocations' }) private disableResponseCategoryOptions!: (offer: LivingAllocationResponse) => boolean;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('responsiblePhysiciansByHospitalAndOrgan', { namespace: 'responsiblePhysicians' }) private responsiblePhysicianOptions!: (byHospitalId: string, byOrganCode: string) => GenericCodeValue[];
  @Getter('recipients', { namespace: 'livingAllocations' }) private recipients!: LivingAllocationRecipient[];
  @Getter('isTransplantCoordinator', { namespace: 'users' }) private isTransplantCoordinator!: boolean;
  @Getter('getUsersTransplantPrograms', { namespace: 'users' }) private usersTransplantPrograms!: string[];
  @Getter('clusterOrganCodeDisplayValue', { namespace: 'utilities' }) private clusterOrganCodeDisplayValue!: (organCode: number|null, clusterOrganCode?: string|null) => string;
  @Getter('organName', { namespace: 'lookups' }) organNameLookup!: (organCode?: number) => string;
  @Getter('getOrganSpecificationName', { namespace: 'lookups' }) getOrganSpecificationName!: (organCode?: number|null, organSpecificationCode?: number|null) => string;
  @Getter('isSurgicalUser', { namespace: 'users' }) private isSurgicalUser!: boolean;
  @Getter('getUserResponsiblephysicianId', { namespace: 'users' }) private responsiblephysicianId!: ObjectId;

  public lookupsToLoad = ['offer_responses', 'exceptional_distribution_acceptance_reasons', 'donor_exceptional_distribution'];
  public allocationResponseErrorMessage: string|undefined = '';

  public selectAllMatchingRows(checked: boolean): void {
    if (checked) {
      this.editState.rows.map((row: LivingAllocationResponse) => {
        // make offer: can do multiple offers regardless of sequence for backup and no-offer
        // check if transplant coordinator & primary/backup offer or just primary/backup offer
        const selectable = this.isTransplantCoordinator ? this.sameTransplantProgram(row) && this.isPrimaryOrBackup(row) : this.isPrimaryOrBackup(row);
        // check no response recorded
        row.selected = (selectable && row.offerType && row.responseDateTime == '-' && row.responseBy == '-') ? true : false;
      });
    } else {
      this.editState.rows.map((row: LivingAllocationResponse) => {
        row.selected = false;
      });
    }
  }

  /**
   * Return a list of AllocationRecipients based on the user.
   *
   * If the user is a Transplant Coordinator the listing should show all
   * AllocationRecipients, regardless of any offer made.  All other users
   * should only see Recipients who have a primary or backup offer.
   *
   * @returns {AllocationRecipient[]} list of Allocation Recipients
   */
  get recipientListing(): LivingAllocationRecipient[] {
    if (this.isTransplantCoordinator) return this.recipients || [];
    return this.allPrimaryBackupOffers || [];
  }

  /**
   * Checks to see if listed for contains kidney and pancreas
   *
   * @param listed_for_codes  all the listed_for organ codes
   * @returns {boolean} true / false
   */
  listedForIncludesKidneyAndPancreasCombination(listed_for_codes: string[]): boolean {
    // if no listed_for codes, return false
    if (!listed_for_codes || Array.isArray(listed_for_codes) && listed_for_codes.length <= 1) return false;
    // if kidney & pancreas whole return true
    const organs: string[] = [];
    listed_for_codes.map((item: string) => {
      // separate organs, if we find one clustered split it so we have one array containing separate organs
      if (item.includes('/')) {
        const separated_organs = item.split('/');
        separated_organs.map((single_organ: string) => {
          organs.push(single_organ);
        });
      } else {
        organs.push(item);
      }
    });
    // deduplicate the array
    const unique_organs = [...new Set(organs)];

    // use that to see if we have a kidney & pancreas
    // ...regardless of whether it's made up of [kidney/lung, pancreas] or [kidney/pancreas, lung] or [kidney, pancreas, lung/liver]
    return unique_organs.includes(OrganCodeValue.Kidney.toString()) && unique_organs.includes(OrganCodeValue.PancreasWhole.toString());
  }

  /**
   * Checks to see if organ listed should be highlighted
   *
   * @param organ_code         the 'organ' row's organ code when it's a single organ
   * @param cluster_organ_code the 'organ' row's organ code when it's a cluster
   * @param listed_for_code    this particular listed_for organ code
   * @param listed_for_codes   all the listed_for organ codes
   * @returns {boolean} true if should be highlighted
   */
  highlightOrgan(organ_code: string, cluster_organ_code: string, listed_for_code: string, listed_for_codes: string[]): boolean {
    const real_organ_code = cluster_organ_code ? cluster_organ_code : organ_code;
    if (!listed_for_code) { return false; }
    if (this.allocation.organ_code != OrganCodeValue.Kidney) { return false; }
    if (real_organ_code == '3/6') { return false; }
    const listedForKidneyPancreas = this.listedForIncludesKidneyAndPancreasCombination(listed_for_codes);
    return real_organ_code == listed_for_code && listedForKidneyPancreas;
  }

  /**
   * Return true if we're allowed to POST with this user
   *
   * @returns {boolean} true if we have POST access
   */
  get showControls(): boolean {
    return this.checkAllowed("/donors/:donor_id/organs/:organ_id/allocations/:allocation_id/offers/respond", "POST");
  }

  /**
   * Get a string representation the organ_code
   *
   * @returns {string} organ_code param as a string
   */
  get organCode(): string {
    return this.$route.params.organ_code ? this.$route.params.organ_code.toString() : '';
  }

  /**
   * Returns an array of options for Organ Specification
   *
   * Fetches the organ specification subtable from the appropriate organ lookup table
   *
   * @returns {OrganSpecification[]} options for organ specification
   */
  get organSpecificationLookup(): OrganSpecification[] {
    if (!this.organLookup || !this.organCode) {
      return [];
    }
    // Retrieve information based on organCode
    const organLookupEntry = this.organLookup.find((organ: Organ) => {
      return organ.code.toString() === this.organCode.toString();
    });
    if (!organLookupEntry || !organLookupEntry.sub_tables) {
      return [];
    }
    // Fetch appropriate options sub table
    const organSpecifications: OrganSpecification[] = organLookupEntry?.sub_tables?.organ_specifications || [];
    const offerOrganSpec: OrganSpecification[] = organSpecifications.filter((organSpec: OrganSpecification) => {
      return !!organSpec.offer;
    });
    return offerOrganSpec;
  }

  get allocationOrganCode(): number|null {
    return this.allocation.organ_code || null;
  }

  /**
   * Return an array of the table headers by Organ Code
   *
   * @returns {string[]} array of table headers for offer response table
   */
  get tableHeaders(): string[] {
    let columns = [
      'selected', 'offerType', 'organ_spec_offered', 'rank', 'client_id', 
      'lastName', 'hospital_abbreviation', 'organ', 'offerDateTime', 'offeredBy', 
      'responseCode', 'responseCategoryCode', 'responseReasonCode', 
      'responseDateTime', 'responsiblePhysician', 'responseBy',
      'medical_status', 'secondary_medical_status', 'hsp', 'allocation_points', 
      'mpe_score', 'abo', 'sex', 'age', 'height', 'weight', 'cpra', 'recipientStatus'
      ];

    switch(this.allocationOrganCode) {
      case OrganCodeValue.Liver:
        columns = columns.filter((item: string) => item !== "secondary_medical_status");
        columns = columns.filter((item: string) => item !== "hsp");
        columns = columns.filter((item: string) => item !== "allocation_points");
        columns = columns.filter((item: string) => item !== "cpra");
        break;
      case OrganCodeValue.Kidney:
        columns = columns.filter((item: string) => item !== "secondary_medical_status");
        columns = columns.filter((item: string) => item !== "mpe_score");
        break;
      case OrganCodeValue.Lung:
        columns = columns.filter((item: string) => item !== "secondary_medical_status");
        columns = columns.filter((item: string) => item !== "hsp");
        columns = columns.filter((item: string) => item !== "allocation_points");
        columns = columns.filter((item: string) => item !== "mpe_score");
        break;
      default:
        // show all
        break;
    }
    return columns;
  }

  /**
   * Return if we have any rows selected
   *
   * @returns {boolean} true if any rows are selected
   */
  get checkForSelectedRows(): boolean {
    return this.getSelectedRows.length > 0 ? true : false;
  }

  /**
   * Return selected rows
   *
   * @returns {AllocationResponse[]} all selected rows
   */
  get getSelectedRows(): LivingAllocationResponse[] {
    return this.editState.rows.filter((row: LivingAllocationResponse) => row.selected === true) || [];
  }

  /**
   * Return selected or completed
   *
   * @returns {AllocationResponse[]} all selected rows
   */
  get getSelectedOrCompletedRows(): LivingAllocationResponse[] {
    return this.editState.rows.filter((row: LivingAllocationResponse) => (row.selected === true || row.responseBy !== '-')) || [];
  }

  /**
   * Return if we skipped any rows
   *
   * Offer responses need to happen in order, this will let us know
   * if any offers were skipped before attempting to respond.
   *
   * @returns {boolean} true if they skipped an offer
   */
  get checkForSkippedResponses() {
    const selectedRows = this.getSelectedRows;
    const selectedProgram = selectedRows[0].program;
    const selectedOrganCode = selectedRows[0].offerOrganCode;
    const skippedRows = this.editState.rows.filter((row: LivingAllocationResponse) => {
      // DIAG: We need to check offerOrganCode because Kidney allocations will contain duplicate recipients (listed for Pancreas and Kidney).
      // The extra record here can make the count seem like we've skipped a row but you can't response to offers of different organ codes at the same time.
      return (!row.selected && row.offerType != undefined) &&
        this.responseIsUndefined(row) &&
        (row.program === selectedProgram) &&
        (selectedOrganCode === row.offerOrganCode);
    });
    const firstSkipped = skippedRows[0] ? skippedRows[0].effective_rank : undefined;
    const lastSelected = selectedRows[selectedRows.length - 1] ? selectedRows[selectedRows.length - 1].effective_rank : undefined;
    if (firstSkipped && lastSelected) {
      return firstSkipped < lastSelected ? true : false;
    } else {
      return false;
    }
  }

  // Check if a value is masked
  public checkMasked(value: string|undefined): boolean {
    if (value == null) return false;
    return isMasked(value);
  }

  // Called when changing the program drop down
  public onFilterProgramsBy(): void {
    const value = this.editState.programFilterValue || '';
    this.searchAllocationsBy('hospital_abbreviation', value);
  }

  // Initialize the form before the page mounts
  public mounted(): void {
    this.$store.dispatch('responsiblePhysicians/loadAllResponsiblePhysicians')
    .then(() => {
      this.initializeOfferResponse();
    });
  }

  /**
   * Watch for changes to the allocations
   *
   * @listens allocation#changed
   */
  @Watch('allocation', { immediate: true, deep: true })
  public initializeOfferResponse() {
    this.$store.commit('pageState/set', {
      pageKey: 'livingAllocationOfferResponses',
      value: {
        rowsAll: this.buildOfferRows(this.recipientListing), // used for searching
        rows: this.buildOfferRows(this.recipientListing), // used for selection, altered
        selectAllMatchingRows: false,
        programFilterValue: null,
        programOptions: this.getHospitalPrograms(this.recipientListing)
      }
    });
  }

  /**
   * Emits a loaded event after all subcomponents have finished loading.
   *
   * @listens allocationOfferResponses#loaded
   * @emits loaded
   */
  public loaded(): void {
    this.$emit('loaded', 'livingAllocationOfferResponses');
  }

  // Update the checkbox in the select column
  public checkRow(event: any, row: any): void {
    if (row && row.effective_rank) {
      if (event.target.checked) {
        this.editState.rows.map((item: any) => {
          if (item.effective_rank === row.effective_rank) {
            item.selected = true;
          }
        });
      } else {
        this.editState.rows.map((item: any) => {
          if (item.effective_rank === row.effective_rank) {
            item.selected = false;
          }
        });
      }
    }

    if (this.hasMultipleProgramsInSelection) {
      this.allocationResponseErrorMessage = this.$t("allocation_multiple_programs").toString();
    } else {
      this.allocationResponseErrorMessage = "";
    }
  }

  get getSelectedRecords(): any[] {
    if (!this.editState.rows) return [];
    return this.editState.rows.filter((item: any) => {
      return item.selected == true;
    });    
  }

  /**
   * Checks selected offers for backup offers with no response code.
   * If user has selected offers from different programs return true.
   *
   * @returns {boolean} true if selected offers from multiple programs
   */
  get hasMultipleProgramsInSelection(): boolean {
    const programs: any[] = [];
    this.getSelectedRecords.map((record: any) => {
      if (this.isPrimaryOrBackup(record)) {
        programs.push(record.hospital_abbreviation);
      }
    });
    // deduplicate program array, if more than one kind, return true
    return [...new Set(programs)].length > 1;
  }


  // Update the respone for a given row
  public updateRow(event: string, idx: number, key: string): void {
    const offerType = this.editState.rows[idx].offerType;
    if (offerType == LivingAllocationOfferResponseCodeValues.Accept) {
      Vue.set(this.editState.rows[idx], 'responseCategoryCode', undefined);
      Vue.set(this.editState.rows[idx], 'responseReasonCode', undefined);
    }
    Vue.set(this.editState.rows[idx], key, event);
  }

  // Return filtered offer_responses: Withdraw and Cancel
  public clearValues(idx: number, keys: string[]): void {
    keys.forEach((k: string) => {
      Vue.set(this.editState.rows[idx], k, null);
    });
  }

  // Return a row given an index
  public rowByIdx(idx: number): LivingAllocationResponse {
    return this.editState.rows[idx];
  }

  // Styling for cells
  public getCellStyle(row: LivingAllocationResponse): string {
    let style = [];
    switch(row.responseCode) {
      case LivingAllocationOfferResponseCodeValues.Accept:
        // primary
        style.push("response-accepted");
        break;
      case LivingAllocationOfferResponseCodeValues.AcceptWithCondition:
        // primary
        style.push("response-accepted");
        break;
      case LivingAllocationOfferResponseCodeValues.Decline:
        // decline
        style.push("response-declined");
        break;
      default:
        break;
    }
    return style.join(" ");
  }

  // Styling for rows
  public getRowStyle(row: LivingAllocationResponse): string {
    let style = [];
    const recipient = this.recipientListing.find((item: LivingAllocationRecipient) => {
      return item.effective_rank === row.effective_rank;
    });
    switch(row.offerType) {
      case LivingAllocationOfferTypeValues.Primary:
        // primary
        style.push("offer-row-primary");
        break;
      case LivingAllocationOfferTypeValues.Backup:
        // backup
        style.push("offer-row-backup");
        break;
      case LivingAllocationOfferTypeValues.NoOffer:
        // no offer (will also have to provide reason_category & reason_code)
        style.push("offer-row-no-offer");
        break;
      default:
        // null = no offer
        style.push("offer-row-unoffered");
        break;
    }
    // offer responses lookup
    if (recipient && recipient.offer?.response_code) {
      switch(recipient?.offer?.response_code) {
        case LivingAllocationOfferResponseCodeValues.Accept:
          style.push("row-response-accepted");
          break;
        case LivingAllocationOfferResponseCodeValues.AcceptWithCondition:
          style.push("row-response-accepted-condition");
          break;
        case LivingAllocationOfferResponseCodeValues.Cancel:
        case LivingAllocationOfferResponseCodeValues.Decline:
        case LivingAllocationOfferResponseCodeValues.Withdraw:
          style.push("row-response-declined");
          break;
        default:
          style.push();
          break;
      }
    }
    if (row.hsp === "HSP") style.push("hsp-row");
    return style.join(" ");
  }

  /**
   * Return value of the option using the code
   *
   * @returns {string} value of the option
   */
  public lookupOptionValue(options: GenericCodeValue[], code: string): string {
    if (!options || !code) return '-';
    const option = options.find((item: GenericCodeValue) => {
      return item.code == code;
    });
    return option? option.value : '-';
  }

  /**
   * Return true if the logged in user is from the same Transplant Program
   *
   * @returns {boolean} true if it's the same transplant program
   */
  public sameTransplantProgram(row: LivingAllocationResponse): boolean {
    if (!row) return false;
    const transplantProgram = row.program ? row.program.toLowerCase() : '';
    return this.usersTransplantPrograms.includes(transplantProgram);
  }

  /**
   * Return true if the row has an offer type of No Offer
   *
   * @returns {boolean} true if no offer
   */
  public isNoOffer(row: LivingAllocationResponse): boolean {
    return row?.offerType === LivingAllocationOfferTypeValues.NoOffer;
  }

  /**
   * Return true if the row has an offer type of No Offer
   *
   * @returns {boolean} true if no offer
   */
  public isPrimaryOrBackup(row: LivingAllocationResponse): boolean {
    return row?.offerType === LivingAllocationOfferTypeValues.Primary || row?.offerType === LivingAllocationOfferTypeValues.Backup;
  }

  /**
   * Return true if the row has a response of Cancel or Withdraw
   *
   * @returns {boolean} true if Cancel or Withdraw
   */
  public isCancelOrWithdraw(row: LivingAllocationResponse): boolean {
    const recipientOffer = this.recipientOffer(`${row._id}`);
    // Check the response code of the original offer not the one we're about to make
    if (recipientOffer?.response_code === LivingAllocationOfferResponseCodeValues.Cancel
        || recipientOffer?.response_code === LivingAllocationOfferResponseCodeValues.Withdraw) {
        return true;
    }
    return false;
  }

  /**
   * Retrun true if the row is respondable
   *
   * @returns {boolean} true if we should show the checkbox
   */
  public showCheckbox(row: LivingAllocationResponse): boolean {
    if (this.checkMasked(row.lastName)) return false;
    if (row.offerType == null) return false;
    if (this.isTransplantCoordinator) {
      if (!this.sameTransplantProgram(row)) return false;
      if (row.offerType === LivingAllocationOfferTypeValues.NoOffer) return false;
      const recipientOffer = this.recipientOffer(`${row._id}`);
      // Check the response code of the original offer not the one we're about to make
      if (recipientOffer?.response_code === LivingAllocationOfferResponseCodeValues.Cancel) return false;
      if (recipientOffer?.response_code === LivingAllocationOfferResponseCodeValues.Withdraw) return false;
    }
    return true;
  }

  // Filter response table
  public onFilterBy(event: any): void {
    const column = event.target.name;
    const value = event.target.value || '';
    this.searchAllocationsBy(column, value);
  }

  // PRIVATE

  // Return the recipient offer
  public recipientOffer(recipientId: string): LivingAllocationOffer|undefined {
    const recipient = this.recipientListing.find((item: LivingAllocationRecipient) => {
      return item._id === recipientId;
    });
    return recipient?.offer;
  }

  /**
   * Filter offer responses by program
   *
   * @param column column we want to filter by
   * @param value value we want to filter by
   */
  private searchAllocationsBy(column: string, value: string): void {
    // Build offerRows from the recipientListing
    const offerRows = this.editState.rowsAll;
    // Filter offerRows
    const filteredOfferRows: LivingAllocationResponse[] = offerRows.filter((item: any) => {
      let props = (value && column) ? [item[column]] : Object.values(item);
      return props.some((prop: any) => {
        const filteredProp = prop || '';
        const returnValue = filteredProp.toLowerCase().includes(value.toLowerCase());
        return !value || (typeof prop === 'string') ? returnValue : null;
      });
    });
    // Update our state with the filteredOfferRows
    Vue.set(this.editState, 'rows', filteredOfferRows);
    Vue.set(this.editState, 'selectAllMatchingRows', false); // uncheck 'select all'
  }

  // Open compare modal
  private openCompareModal(recipientId: any): void {
    (this.$refs.compareModal as CompareModal).initializeAllocationCompare(recipientId);
  }

  // Sanitize 'Listed For' information
  private listedFor(record: LivingAllocationRecipient): string[] {
    let result: string[] = record.waitlisted_for_organs && record.waitlisted_for_organs.length > 0 ? record.waitlisted_for_organs : [this.clusterOrganCodeDisplayValue(record.organ_code, record.cluster_organ_code)];

    // Combine the organ listing information for Out-of-Province cluster entry
    if (record.out_of_province && record.registration_type === RegistrationType.Cluster) {
      result = [result.join('/')];
    }

    return result;
  }

  // Sanitize 'Listed For Codes' information
  private listedForCodes(record: LivingAllocationRecipient): string[] {
    let result: string[] = record?.waitlisted_for_organ_codes || [];

    // Combine the organ listing information for Out-of-Province cluster entry
    if (record.out_of_province && record.registration_type === RegistrationType.Cluster) {
      result = [result.join('/')];
    }

    return result;
  }

  /**
   * Builds row data for the Offer Responses table
   *
   * @returns {AllocationRecipient[]} Allocation Recipients table rows
   */
  private buildOfferRows(openOffers: LivingAllocationRecipient[]): LivingAllocationResponse[] {
    if (openOffers.length <= 0) {
      return [];
    }
    const result: LivingAllocationResponse[] = [];
    openOffers.forEach((record: LivingAllocationRecipient) => {
      const responseCode = record.offer?.response_code;
      const responsiblePhysician = record.offer?.responsible_physician_id ? record.offer?.responsible_physician_id : this.isSurgicalUser ? this.responsiblephysicianId?.$oid : NO_RESPONSIBLE_PHYSICIAN;
      const row: any = {
        selected: false,
        _id: record._id,
        offerType: record.offer?.offer_type_code || undefined,
        organ_spec_offered: record.offer && record.offer?.organ_specification_code ? this.getOrganSpecificationName(this.allocation.organ_code, record.offer?.organ_specification_code) : null,
        rank: !record.added_manually? record.effective_rank : undefined, // effective_rank is the rank to be disaplyed and used
        effective_rank: record.effective_rank || undefined,
        client_id: record.client_id || undefined,
        lastName: record.last_name || '-',
        hospitalId: record.hospital_id,
        program: record.program || '-',
        hospital_abbreviation: record.hospital_abbreviation || record.program || '-',
        organ: this.clusterOrganCodeDisplayValue(record.organ_code, record.cluster_organ_code),
        offerOrganCode: record.organ_code,
        listed_for: this.listedFor(record),
        listed_for_codes: this.listedForCodes(record),
        offerDateTime: this.parseDisplayDateTimeUiFromDateTime(record.offer?.datetime_offered) || '-',
        offeredBy: record.offer?.offered_by || '-',
        responseCode: responseCode || undefined,
        responseCategoryCode: record.offer?.response_reason_category_code || null,
        responseReasonCode: record.offer?.response_reason_code || null,
        hsp: record.hsp || '-',
        responseDateTime: this.parseDisplayDateTimeUiFromDateTime(record.offer?.response_date) || '-',
        responsiblePhysician: responsiblePhysician || NO_RESPONSIBLE_PHYSICIAN,
        responseBy: record.offer?.response_by || '-',
        recipientStatus: record.status || '-',
        outOfProvince: record.out_of_province,
        medical_status: record.medical_status || '-',
        secondary_medical_status: record.secondary_medical_status || '-',
        allocation_points: record.allocation_points == null ? '-' : record.allocation_points,
        mpe_score: record.mpe_score || null,
        abo: record.blood_type || '-',
        sex: record.sex || '-',
        age: record.age === 0 ? 0 : (record.age || undefined),
        height: record.height || undefined,
        weight: record.weight || undefined,
        cpra: record.cpra === 0 ? 0 : (record.cpra || undefined),
        /**
         * Prevent user from opening compare modal if we have manually added an Out-of-Province Program
         * Note: this assumes that the presence of both the 'added_manually' and 'out_of_province' flags
         * AS WELL AS the absence of 'last_name' is how we should distinguish the OOP Programs
         */
        disableCompareModal: record.added_manually && record.out_of_province && !record.last_name,
      };
      result.push(row);
    });
    return result;
  }

  // Extract patch for API
  private extractAllocationResponsePatch(responses: LivingAllocationResponse[]): LivingAllocationResponseAction[] {
    const result: LivingAllocationResponseAction[] = [];
    // filter response to those that have a responseCode
    const filteredResponses = responses.filter((reponse: LivingAllocationResponse) => {
      return reponse.responseCode != null;
    });
    // build payload for each response
    filteredResponses.forEach((response: LivingAllocationResponse) => {
      let reason_category: number|null = null;
      let reason_code: number|null = null;

      // Ensure null is sent if no responsible physician selected (e.g. Out-of-province entry)
      let responsible_physician_id = (response.responsiblePhysician !== NO_RESPONSIBLE_PHYSICIAN) ? response.responsiblePhysician : null;

      // add reason and category if we're accepting with condition
      if (response.responseCode == LivingAllocationOfferResponseCodeValues.AcceptWithCondition || response.responseCode == LivingAllocationOfferResponseCodeValues.Decline || response.responseCode == LivingAllocationOfferResponseCodeValues.RequestExtension) {
        reason_category = response.responseCategoryCode ? response.responseCategoryCode : null;
        reason_code = response.responseReasonCode ? response.responseReasonCode : null;
      }
      // build response payload
      const filteredResponse = {
        recipient_id: response._id,
        type: response.responseCode,
        reason_category: reason_category || null,
        reason_code: reason_code || null,
        responsible_physician_id: responsible_physician_id || null,
        offer_organ_code: response.offerOrganCode
      };
      result.push(filteredResponse);
    });
    return result;
  }

  private responseIsUndefined(item: any): boolean {
    // A NoOffer response can be skipped
    if (item.offerType === LivingAllocationOfferTypeValues.NoOffer) return false;
    switch(item.responseCode) {
      // These responses can be skipped
      case LivingAllocationOfferResponseCodeValues.Accept:
      case LivingAllocationOfferResponseCodeValues.Cancel:
      case LivingAllocationOfferResponseCodeValues.Withdraw:
      case LivingAllocationOfferResponseCodeValues.RequestExtension:
        return false;
        break;
      // These responses can be skipped if they have a response, category and reason code
      case LivingAllocationOfferResponseCodeValues.AcceptWithCondition:
      case LivingAllocationOfferResponseCodeValues.Decline:
        return item.responseCode === undefined || item.responseCategoryCode == null || item.responseReasonCode === null;
        break;
      // Everything else is mandatory
      default:
        return true;
        break;
    }
  }

  private checkPatchForAcceptedOffers(patch: any): boolean {
    let accepted = false;
    patch.map((item: any) => {
      if (item.type === LivingAllocationOfferResponseCodeValues.Accept) { accepted = true; }
      if (item.type === LivingAllocationOfferResponseCodeValues.AcceptWithCondition) { accepted = true; }
    });
    return accepted;
  }

  get selectRowsIncludeNoResponsiblePhysician(): boolean {
    let physicianSelected = false;
    // compare against selected rows
    this.getSelectedRows.map((row: LivingAllocationResponse) => {
      const responsiblePhysician = row.responsiblePhysician || NO_RESPONSIBLE_PHYSICIAN;
      if (responsiblePhysician == NO_RESPONSIBLE_PHYSICIAN && !row.outOfProvince) { physicianSelected = true; }
    });
    return physicianSelected;
  }

  get multiplePrimaryOffersSelected(): boolean {
    const organs: any[] = [];
    // compare against previous and selected offers
    this.getSelectedOrCompletedRows.map((row: LivingAllocationResponse) => {
      if ((row.offerType == LivingAllocationOfferTypeValues.Primary && row.responseCode == LivingAllocationOfferResponseCodeValues.Accept) ||
        (row.offerType == LivingAllocationOfferTypeValues.Primary && row.responseCode == LivingAllocationOfferResponseCodeValues.AcceptWithCondition)) { 
        organs.push(row.organ_spec_offered); 
      }
    });
    // check for duplicate primary organ offers
    return new Set(organs).size !== organs.length;
  }

  // Respond to offers
  private respondOffers(): void {
    if (this.multiplePrimaryOffersSelected) {
      this.allocationResponseErrorMessage = this.$t('multiple_primary_offers_selected').toString();
    } else if (this.checkForSkippedResponses) {
      this.allocationResponseErrorMessage = this.$t('higher_ranking_not_responded').toString();
    } else if (this.getSelectedRows.filter((item) => this.responseIsUndefined(item)).length > 0 ) {
      this.allocationResponseErrorMessage = this.$t('must_have_valid_response').toString();
    } else if (this.selectRowsIncludeNoResponsiblePhysician) {
      this.allocationResponseErrorMessage = this.$t('must_have_valid_responsible_physician').toString();
    } else {

      // clear error message
      this.allocationResponseErrorMessage = "";

      // build patch from edited rows
      const selectedRows = this.getSelectedRows;

      // if not expedited, accept normally
      const patch = this.extractAllocationResponsePatch(selectedRows);
      const exceptional_distribution = this.allocation.donor.exceptional_distribution || false;

      const isAccepted = this.checkPatchForAcceptedOffers(patch);

      // if exceptional_distribution, open Accept Dialog
      if (exceptional_distribution && isAccepted) {
        const offerResponseAccept = this.$refs.offerResponseAccept as OfferResponseAccept;
        offerResponseAccept.initializeModal(patch, this.organCode);
      } else {
        const payload = {
          clientId: this.clientId,
          organCode: this.organCode,
          allocationId: this.allocation._id,
          responseDetails: patch
        };
        this.$store.commit('livingAllocations/startRespondingOffer');
        this.$store.commit('livingAllocations/startLoading');
        this.$store.dispatch('livingAllocations/respondOffer', payload).then((success: any) => {
          this.$store.commit('livingAllocations/stopRespondingOffer');
          this.reloadTable();
        }).catch((error: any) => {
          this.allocationResponseErrorMessage = error;
          if(error) {
            // TODO: TECH_DEBT: 
            // Now we can show the error message only using alert, because the refs are not available until component is completed loading 
            const error_message = this.getErrorMessage(error);
            alert(error_message);
            this.reloadTable();
          }
          this.$store.commit('livingAllocations/stopLoading');
          this.$store.commit('livingAllocations/stopRespondingOffer');
        });
      }
    }
  }

  // Decline multiple
  private declineOffers(): void {
    if (this.checkForSkippedResponses) {
      this.allocationResponseErrorMessage = this.$t('higher_ranking_not_responded').toString();
    } else {
      this.allocationResponseErrorMessage = "";

      // build patch from edited rows
      const selectedRows = this.getSelectedRows;

      // open decline-multiple dialog
      const offerResponseDecline = this.$refs.offerResponseDecline as OfferResponseDecline;
      offerResponseDecline.initializeModal(selectedRows, this.offerResponses, this.organCode);
    }
  }

  // Get all Active Allocations which will reload this component
  private reloadTable(): void {
    this.$store.commit('allocations/startLoading');
    Promise.all([
      this.$store.dispatch('livingAllocations/getAllocations', { clientId: this.clientId, state: 'active' }),
      this.$store.dispatch('livingAllocations/getAllocation', { clientId: this.clientId, organCode: this.organCode, allocationId: this.allocation._id }),
    ]).finally(() => {
      Vue.set(this.editState, 'selectAllMatchingRows', false);
      this.$store.commit('allocations/stopLoading');
    });
  }
}
