
import {Component, Prop} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {DateUtilsMixin} from "@/mixins/date-utils-mixin";
import {isMasked} from "@/utils";
import SubSection from "@/components/shared/SubSection.vue";
import {Getter, State} from "vuex-class";
import {RecipientsSearchResult} from "@/store/recipients/types";
import {TableConfig} from "@/types";

@Component({
  components: {
    SubSection
  }
})
export default class DeceasedDonorsSearchResults extends mixins(DateUtilsMixin) {

  @State(state => state.deceasedDonors.donorsList) public donors!: RecipientsSearchResult;
  @Prop({required: true}) searchedTerm!: string;
  loading = false;
  pageSize = 25;
  currentPage = 1;

  get donorsColumns(): any[] {
    return [
      {
        label: `${this.$t('deceased_donor_id')}`,
        field: 'deceased_donor_id',
        sortable: false,
        filterOptions: {
          enabled: false
        }
      },
      {
        label: `${this.$t('system_id')}`,
        field: 'client_id',
        sortable: false,
        filterOptions: {
          enabled: false
        }
      },
      {
        label: `${this.$t('hospital_name')}`,
        field: 'hospital_name',
        sortable: false,
        filterOptions: {
          enabled: false
        }
      },
      {
        label: `${this.$t('national_id')}`,
        field: 'national_id',
        sortable: false,
        filterOptions: {
          enabled: false
        }
      },
      {
        label: `${this.$t('first_name')}`,
        field: 'first_name',
        sortable: false,
        filterOptions: {
          enabled: false
        }
      },
      {
        label: `${this.$t('last_name')}`,
        field: 'last_name',
        sortable: false,
        filterOptions: {
          enabled: false
        }
      },
      {
        label: `${this.$t('age')}`,
        field: 'age',
        sortable: false,
        filterOptions: {
          enabled: false
        }
      },
      {
        label: `${this.$t('sex')}`,
        field: 'sex',
        width: "130px",
        sortable: false,
        filterOptions: {
          enabled: false
        }
      },
      {
        label: `${this.$t('cause_of_death')}`,
        field: 'cause_of_death',
        sortable: false,
        filterOptions: {
          enabled: false
        }
      },
      {
        label: `${this.$t('referral_date')}`,
        field: 'referral_date',
        sortable: false,
        filterOptions: {
          enabled: false
        },
        width: '130px',
        thClass: 'vgt-left-align',
        tdClass: 'vgt-left-align',
        formatFn: this.formatDate
      },
      {
        label: `${this.$t('mrn')}`,
        field: 'mrn',
        width: "50px",
        sortable: false,
        filterOptions: {
          enabled: false
        }
      }
    ];
  }
  formatDate(value: string): string|undefined {
    return isMasked(value) ? value : this.parseDisplayDateUi(value);
  }

  mounted() {
    if(this.searchedTerm) {
      this.searchDonors();
    }
  }

  searchDonors() {
    this.loading = true;
    const searchCriteria: any = {
      search_strategy: 'or'
    };
    ['first_name', 'last_name', 'national_recipient_id', 'client_id'].forEach(k => {
      searchCriteria[k] = this.searchedTerm;
    });
    const mappedParams: string[] = [];
    Object.keys(searchCriteria).forEach((k: any) => mappedParams.push(`${k}=${searchCriteria[k]}`));

    const dispatchMethod = 'deceasedDonors/getList';
    this.$store.dispatch(
      dispatchMethod,
      {
        pageNumber: this.currentPage,
        pageSize: this.pageSize,
        search_params: `&${mappedParams.join('&')}`,
        oopResults: false
      }
    ).then(() => {
      this.loading = false;
    });
  }

  get donorsTableConfig(): TableConfig {
    return {
      data: this.donors.entries,
      columns: this.donorsColumns,
      empty: 'Cannot find any Donors that match the selected filters',
      createButton: false,
      pagination: true,
      sortOptions: {
        enabled: true
      },
      paginationOptions: {
        enabled: true,
        perPage: this.pageSize,
        mode: 'pages',
        perPageDropdown: [10, 25, 100],
        setCurrentPage: this.currentPage,
        dropdownAllowAll: false,
        nextLabel: 'Older',
        prevLabel: 'Newer',
        rowsPerPageLabel: 'Results per page',
        position: 'bottom'
      }
    };
  }

  public updatePagination(event: any) {
    this.currentPage = event.currentPage;
    this.pageSize = event.currentPerPage;

    this.searchDonors();
  }

  public selectDonor(event: any) {
    if (event.row.client_id !== undefined) {
      const clientId: string = event.row.client_id.toString();
      const routerUrl = 'edit-deceased-donor';
      this.$router.push({
        name: routerUrl,
        params: {
          id: clientId,
        }
      });
    } else {
      console.warn('Missing donor clientId');
      this.$router.push({name: 'list-deceased-donors'});
    }
  }

}
