
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import {Getter, State} from "vuex-class";
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {SaveableSection, SaveProvider, SaveResult} from "@/types";
import {LivingDonor, LivingDonorProfileDetails} from "@/store/livingDonors/types";
import {Ethnicity, Gender, InsurancePlanCode, InsuranceType, Province} from '@/store/lookups/types';
import DateInput from "@/components/shared/DateInput.vue";
import TextInput from "@/components/shared/TextInput.vue";
import SubSection from "@/components/shared/SubSection.vue";
import CardSection from "@/components/shared/CardSection.vue";
import SaveToolbar from "@/components/shared/SaveToolbar.vue";
import SelectInput from "@/components/shared/SelectInput.vue";
import NumberInput from "@/components/shared/NumberInput.vue";
import CheckboxInput from "@/components/shared/CheckboxInput.vue";
import TextAreaInput from "@/components/shared/TextAreaInput.vue";
import SelectOtherInput from "@/components/shared/SelectOtherInput.vue";
import {calculateAge} from "@/utils";

interface DemographicsForm {
  internal: {
    living_donor_id?: number;
    registration_date?: string;
    registration_time?: string;
  };
  personal: {
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    date_of_birth?: string;
    age?: number | null;
    sex?: string | null;
    gender?: string | null;
    gender_sex_different?: boolean;
    gender_other?: string | null;
    ethnicity?: string| null;
    ethnicity_other?: string| null;
    insurance_type?: number| null;
    insurance_other?: number| null;
    insurance_number?: string| null;
    insurance_province?: string| null;
  };
}

@Component({
  components: {
    TextInput,
    DateInput,
    SubSection,
    CardSection,
    SaveToolbar,
    SelectInput,
    NumberInput,
    CheckboxInput,
    SelectOtherInput,
    TextAreaInput
  }
})
export default class Demographics extends mixins(DateUtilsMixin) implements SaveableSection {
  // State
  @State(state => state.pageState.currentPage.demographics) editState!: DemographicsForm;
  @State(state => state.lookups.province) provinceLookup!: Province[];
  @State(state => state.lookups.ethnicity) ethnicityLookup!: Ethnicity[];
  @State(state => state.lookups.insurance_plan_codes) insurancePlanCodeLookup!: InsurancePlanCode[];
  @State(state => state.lookups.gender) genderLookup!: Gender[];

  // Properties
  @Prop({ default: false }) newLivingDonor!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  // Getters
  @Getter('show', { namespace: 'livingDonors' }) private livingDonor!: LivingDonor;
  @Getter('clientId', { namespace: 'livingDonors' }) private livingDonorId!: string;
  @Getter('sexOptions', { namespace: 'lookups' }) sexOptions!: Gender[];
  @Getter('getDobMinDateLimit', { namespace: 'lookups' }) private getDobMinDateLimit!: string;
  @Getter('getDobMaxDateLimit', { namespace: 'lookups' }) private getDobMaxDateLimit!: string;
  @Getter('getMaskFormatType', { namespace: 'lookups' }) getMaskFormatType!: (code: number) => string;

  // Lookup tables to be loaded by the CardSection component
  public lookupsToLoad = [
    "ethnicity",
    "gender",
    "insurance_plan_codes",
    "country"
  ];

  /**
   * Get a number for the age of the Living Donor
   *
   * Calculates the age of the Living Donor using the value of Date of Birth
   *
   * @returns {number|null} Living Donor's age or null
   */
  get calculatedAge() {
    const livingDonorDoB = this.editState.personal.date_of_birth || null;
    if (livingDonorDoB) {
      return calculateAge(livingDonorDoB);
    }
    return null;
  }

  /**
   * If Insurance type is other return true
   *
   * @returns {boolean} true if other
   */
  get showOtherProvinceInsurance(): boolean {
    if (!this.editState.personal) {
      return false;
    }
    return (
      this.editState.personal.insurance_type == InsuranceType.OtherProvince
    );
  }

  /**
   * Returns Province dropdown without ontario for the 'other provinces' dropdown
   *
   * @returns {Province[]} list of Provinces
   */
  get otherProvinceDropdown(): Province[] {
    const ontarioIndex = this.provinceLookup.findIndex( element => element.code === "ON");
    return this.provinceLookup.filter((element, index) => {
        return index === ontarioIndex ? false : element;
      }
    );
  }

  /**
   * Applies to OHIP and Other Canadian Province only
   *
   * @returns {boolean} true if we can show insurance number
   */
  get showNumber(): boolean {
    if (!this.editState || !this.editState.personal) return false;
    // If insurance type is OHIP or Other Province
    return this.editState.personal.insurance_type == InsuranceType.OHIP
      || this.editState.personal.insurance_type == InsuranceType.OtherProvince;
  }

  // Initialize the form before the page mounts
  public mounted(): void {
    this.initializeForm();
  }

  // Initialize the form
  public initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'demographics',
      value: this.buildDemographicsForm(this.livingDonor)
    });
  }

   // Translate from relevant parts of the livingDonor data structure to the form layout
  public buildDemographicsForm(livingDonor: LivingDonor): DemographicsForm {
    const livingDonorProfile = livingDonor.patient_profile || {};
    const livingDonorInsurance = livingDonorProfile.insurance || {};
    const genderSexDifferent = !!livingDonorProfile.gender_sex_different;
    const journeys = livingDonor.living_donor_info?.journeys;
    const registrationDate = journeys ? journeys[0].registration_date : undefined;
    return {
      internal: {
        living_donor_id: livingDonor.living_donor_id,
        registration_date: this.parseDateUiFromDateTime(registrationDate),
        registration_time: this.parseTimeUiFromDateTime(registrationDate),
      },
      personal: {
        first_name: livingDonorProfile.first_name,
        middle_name: livingDonorProfile.middle_name,
        last_name: livingDonorProfile.last_name,
        date_of_birth: livingDonorProfile.birth ? this.parseDateUi(livingDonorProfile.birth!.date) : undefined,
        sex: livingDonorProfile.sex,
        gender: genderSexDifferent ? livingDonorProfile.gender : null,
        gender_sex_different: livingDonorProfile.gender_sex_different,
        gender_other: livingDonorProfile.gender_other,
        ethnicity: livingDonorProfile.ethnicity_code,
        ethnicity_other: livingDonorProfile.ethnicity_other,
        insurance_type: livingDonorInsurance.plan_code,
        insurance_province: livingDonorInsurance.other_province_plan,
        insurance_other: livingDonorInsurance.other_plan,
        insurance_number: livingDonorInsurance.number,
      },

    };
  }

  // Triggered when all the lookups have been loaded
  public loaded(): void {
    this.$emit('loaded', 'demographics');
  }

  // Handle saving triggered by local save button
  public savePatch(potential_duplicate_profile_confirmed?: boolean): void {
    // Refer to the save provider that handles this form area
    const saveProvider = (this.$refs.saveDemographics as unknown) as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'demographics');
    // Generate payload based on current edit state
    const livingDonorPatch = this.extractPatch(potential_duplicate_profile_confirmed);
    // Dispatch save action and register the response
    this.$store
      .dispatch('livingDonors/saveLivingDonor', {
        livingDonorId: this.livingDonorId,
        livingDonor: livingDonorPatch
      }).then((success: SaveResult) => {
        // If successful, update the current livingDonor and show success notification
        this.$store.commit('livingDonors/set', success.responseData.living_donor);
        saveProvider.registerSaveResult(success);
        this.initializeForm();
      }).catch((error: SaveResult) => {
        // Emit event to handle errors
        this.$emit('handleErrors', error);
        // Show error notification
        saveProvider.registerSaveResult(error);
      });
  }

  // Translate from the form layout to relevant parts of the livingDonor data structure
  public extractPatch(potential_duplicate_profile_confirmed?: boolean): LivingDonor {
    const editStatePersonal = this.editState.personal;

    const updatedProfile: LivingDonorProfileDetails = {
      first_name: editStatePersonal.first_name,
      middle_name: editStatePersonal.middle_name,
      last_name: editStatePersonal.last_name,
      age: this.calculatedAge? this.calculatedAge : undefined,
      birth: {
        date: this.sanitizeDateApi(this.editState.personal.date_of_birth) || null
      },
      sex: editStatePersonal.sex || null,
      gender_sex_different: editStatePersonal.gender_sex_different ? true : false,
      gender: (editStatePersonal.gender_sex_different && editStatePersonal.gender) || null,
      gender_other: (editStatePersonal.gender_sex_different && editStatePersonal.gender_other) || null,
      ethnicity_code: editStatePersonal.ethnicity || null,
      ethnicity_other: editStatePersonal.ethnicity_other || null,
      insurance: {
        plan_code: editStatePersonal.insurance_type,
        number: editStatePersonal.insurance_number || null,
        other_plan: editStatePersonal.insurance_other || null,
        other_province_plan: editStatePersonal.insurance_province || null
      }
    };

    return {
      _id: this.livingDonor._id,
      patient_profile: updatedProfile,
      potential_duplicate_profile_confirmed: potential_duplicate_profile_confirmed || false
    };
  }

  // Reset the saveToolbar to clear the message
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    const saveProvider = (this.$refs.saveDemographics as unknown) as SaveProvider;
    // Reset the save provider's save toolbar
    saveProvider.resetSaveToolbar();
  }

  // PRIVATE

  // Clear values from state given an array of keys
  private clearStateValues(keys: string[]): void {
    if (!Array.isArray(keys) || keys.length === 0) return;
    // For each key, clear the value in our state
    keys.forEach((key: string) => {
      Vue.set(this.editState.personal, key, undefined);
    });
  }

  // API response keys on the left, id for our UI on the right
  get idLookup(): {[key: string]: string} {
    return {
      "patient_profile.first_name": "demographics-firstname",
      "patient_profile.last_name": "demographics-lastname",
      "patient_profile.birth.date": "demographics-dob",
      "patient_profile.ethnicity_code": "ethnicity_code",
      "patient_profile.insurance.number": "demographics-insurancenumber",
      "patient_profile.ethnicity_other": "demographics-ethnicityother",
      "patient_profile.sex": "demographics-sex",
      "patient_profile.gender_sex_different": "demographics-gender-different",
      "patient_profile.gender": "gender",
      "patient_profile.gender_other": "demographics-other-gender",
      "patient_profile.insurance.plan_code": "demographics-insurancetype",
      "patient_profile.insurance.other_plan": "demographics-insuranceother",
      "patient_profile.insurance.other_province_plan": "demographics-provinceother",
    };
  }
}
