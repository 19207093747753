import { PatientProfile, ObjectId } from '@/store/types';
import { LabVirology, LabHlaTypingDonor } from '@/store/labs/types';

export interface DeceasedDonorsState {
  selected?: DeceasedDonor;
  donorsList?: StateDonorList;
  exdConfirmations?: any;
  organDonations?: DeceasedDonorOrganDonations[]|null;
  significantEvents?: DonorSignificantEvent[]|null;
  attachments?: DeceasedDonorAttachment[];
}

export interface StateDonorList {
  count?: number;
  entries?: ListDonor[];
  page_number?: number;
  page_size?: number;
  sort_direction?: string;
  sort_name?: string;
  selectedColumns: string[];
}

// interface for entries in API index response
export interface ListDonor {
  client_id?: number;
  deceased_donor_id?: number;
  hospital_name?: string;
  first_name?: string;
  last_name?: string;
  age?: number;
  sex?: string;
  ecd?: string;
  weight: string;
  height: string;
  blood_type?: string;
  rh?: string;
  measurements?: any[];
  cause_of_death?: string;
  donor_type: boolean|string|null;
  ecd_donor?: boolean;
  out_of_province?: boolean|null;
  tissue_donor?: boolean|null;
  organ_donor?: boolean|null;
  unknown_donor_type?: boolean|null;
  organ_consents?: DeceasedDonorOrgan[];
  referral_type?: string;
  referral_date?: string;
  national_id?: string;
  mrn?: string;
}

export interface DeceasedDonorsList {
  count?: number;
  entries?: DeceasedDonor[];
  page_number?: number;
  page_size?: number;
  sort_direction?: string;
  sort_name?: string;
}

export interface DeceasedDonor {
  _id?: ObjectId;
  client_id?: number;
  deceased_donor_id?: number;
  indicators?: DeceasedDonorIndicators;
  referral?: DeceasedDonorReferral;
  death?: DeceasedDonorDeath;
  blood?: DeceasedDonorBlood;
  measurements?: DeceasedDonorMeasurement[];
  next_of_kin?: DeceasedDonorNextOfKin[];
  consented_for_research?: boolean;
  organ_retrieval_surgery?: DeceasedDonorOrganRetrievalSurgery;
  organ_decline_reason_type?: number;
  organ_decline_reason_category?: number;
  tissue_decline_reason_type?: number;
  tissue_decline_reason_category?: number;
  mr_admitting_diagnosis?: string;
  comments?: string;
  updated_at?: string;
  created_at?: string;
  migration_comment?: string;
  virology_labs?: LabVirology[];
  hla_typing?: LabHlaTypingDonor;
  patient_profile?: PatientProfile;
  organ_consents?: DeceasedDonorOrgan[];
  attachments?: DeceasedDonorAttachment[];
  organ_donations?: DeceasedDonorOrganDonations[];
  allocation_unavailable_due_to_positive_hbv_surface_ag?: boolean;
  allocation_must_be_expedited_due_to_missing_virology?: { [key: string]: string[] };
  allocation_must_be_expedited_due_to_missing_hla_typing?: number[];
  migrated_donor?: boolean;
}

export interface DeceasedDonorOrganDonations {
  _id?: ObjectId;
  organ_code?: number;
  organ_specification_code?: number|null;
  for_research?: boolean;
  organ_offer?: DeceasedDonorOrganDonationOffer;
  not_useable?: boolean;
  not_useable_date?: string;
  not_useable_reason_category_code?: number;
  not_useable_reason_code?: number;
  not_useable_reason_code_other?: string;
  organ_recovery?: DeceasedDonorOrganRecovery[];
  transplanted_date?: string|null;
  updated_by?: string;
  created_by?: string;
  updated_at?: string;
  created_at?: string;
  outcome_code?: number;
  exported_oop?: boolean;
  transplanted_organ_specification_code?: number|null;
  transplanted_opo_recipient?: TransplantedOpoRecipient;
}

export interface TransplantedOpoRecipient {
  age?: number|null;
  gender?: string|null;
  comments?: string|null;
}

export interface DeceasedDonorOrganDonationOffer {
  allocation_id?: ObjectId;
  allocation_client_id?: number;
  recipient_id?: ObjectId;
  recipient_client_id?: number;
  recipient_first_name?: string;
  recipient_last_name?: string;
  recipient_age?: number;
  responsible_physician_id?: string;
  exceptional_distribution?: OrganDonationExceptionalDistribution;
  confirmations?: OrganDonationExceptionalDistributionConfirmation[];
  oop_recipient?: boolean|null;
}

export interface DonorConsentedOrganRecoveryForm {
  label: string;
  organ_code: number;
  organ_specification_code: number|null;
  organ_donation_id: string
}

export interface OrganDonationExceptionalDistribution {
  accepted_date?: string;
  accepted_by_physician?: string;
  accepted_reason_code?: number;
  accepted_reason_other?: string;
  confirmed?: boolean;
}

export interface OrganDonationExceptionalDistributionConfirmation {
  confirmed_date?: string;
  confirmed_by_physician?: string;
  confirmed_reason_code?: number|null;
  confirmed_reason_other?: string;
}

export interface DeceasedDonorOrganRecovery {
  _id?: ObjectId;
  packing_forms?: DeceasedDonorOrganPackagingForms;
  organ_recovered?: boolean;
  ex_vivo?: boolean;
  vessels_recovered?: boolean;
  destination_program?: ObjectId | null;
  destination_program_identifier?: string;
  destination_oop?: string;
  destination_research?:  ObjectId | null;
  retrieved_date?: string|null;
  shipped_date?: string|null;
  shipping_method_code?: number;
  shipping_method_other?: string;
  delivered_date?: string|null;
  received_by?: string;
  updated_by?: string;
  created_by?: string;
  updated_at?: string;
  created_at?: string;
}

export interface DeceasedDonorOrganPackagingForms {
  osdif?: boolean;
  osdif_filename?: string;
  cooler_sheet?: boolean;
  cooler_sheet_filename?: string;
  internal_label?: boolean;
  internal_label_filename?: string;
  external_label?: boolean;
  external_label_filename?: string;
  updated_by?: string;
  created_by?: string;
  updated_at?: string;
  created_at?: string;
}

export interface DeceasedDonorIndicators {
  read_only?: boolean;
  from_itransplant?: boolean;
  deceased_donor?: boolean;
  deceased_at_referral_time?: boolean;
  mr_complete?: boolean;
  out_of_province?: boolean;
  tissue_donor?: boolean;
  organ_donor?: boolean;
  unknown_donor_type?: boolean;
  ecd_donor?: boolean;
  living_exchange?: boolean;
  anonymous_living_donor?: boolean;
  registered_consent_decision?: string;
  exceptional_distribution?: boolean;
  exd_reason_codes?: number[]|null;
  exd_reason_details_other?: string|null;
  exd_reason_details_travel?: string|null;
  exd_reason_details_transmission?: string|null;
  kidney_paired_donor?: boolean;
  heart_beat_at_referal?: string;
  glasgow_coma_scale?: string;
  withdrawal_discussion?: string;
  approach_over_the_phone?: string;
  double_kidney?: boolean;
  ventilator_at_referral?: string;
  ventilator_status?: string|null;
  ventilator_type?: string|null;
  ventilator_type_other?: string;
  post_release?: boolean;
  post_release_reason?: string;
  results_received?: boolean|null;
  results_received_date?: string|null;
  results_reported?: boolean|null;
  results_reason?: string;
  results_reported_date?: string|null;
}
export interface DeceasedDonorReferral {
  referral_date?: string|null;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  extension?: string;
  fax?: string;
  managing_coordinator_id?: { $oid?: string };
  referring_coordinator_id?: { $oid?: string };
  referer_profession_code?: string;
  referring_hospital_id?: { $oid?: string }|null;
  referring_hosp_unit_code?: string;
  referring_hospital_mrn?: string;
  originating_hospital_id?: { $oid?: string };
  originating_hosp_unit_code?: string;
  originating_hospital_mrn?: string;
}
export interface DeceasedDonorDeath {
  death_date?: string;
  cause_of_death_code?: number;
  neurological_death?: boolean;
  first_declarer?: string;
  first_declare_date?: string|null;
  second_declarer?: string;
  second_declare_date?: string;
  hospital_id_declared?: { $oid?: string|null } | null;
  retest_declarer?: string;
  retest_declare_date?: string;
  hospital_id_retested?: { $oid?: string };
  hospital_id_retested_primary?: { $oid?: string };
  other_cause_of_death?: string;
}
export interface DeceasedDonorBlood {
  type?: string|null;
  sub_type?: string|null;
  rh?: string|null;
}
export interface DeceasedDonorMeasurement {
  _id?: ObjectId;
  weight?: number;
  height?: number;
  abdominal_girth?: number;
  chest_circumference?: number;
  chest_vertical_left?: number;
  chest_vertical_right?: number;
  lung_horizontal_measu?: number;
  cardiac_silhouette?: number;
  total_lung_capacity?: number;
  bronchoscopy_comments?: string;
  suction_comments?: string;
}
export interface DeceasedDonorOrgan {
  // properties from Organ Consent data model
  _id?: ObjectId;
  organ_code?: number;
  organ?: string;
  consented?: boolean;
  consented_date?: string|null;
  ecd_donor?: boolean;
  // derived by webapp API
  running_allocation_tasks?: any;
  available_allocation_types?: string[]; // what allocation types you have access to (see B#14548)
  runnable_allocation_types?: string[]; // what allocation types you can run (see B#14548)
}

export interface DeceasedDonorOrganTask {
  category?: string;
  response?: string;
  task_identifier?: string;
  _id?: ObjectId;
}

export interface DeceasedDonorAllocationSummary {
  display_name: string;
  route: any;
  organ?: string;
  organ_code?: number;
  consented_date?: string|null;
  allocationId?: ObjectId;
  allocationRunDateTime?: string;
  allocationStage?: string;
}

export interface DeceasedDonorNextOfKin {
  first_name?: string;
  last_name?: string;
  relationship_code?: string;
  country?: string;
  province?: string;
  town?: string;
  street?: string;
  postal_code?: string;
  phone_number?: string;
  alternative_phone_number?: string;
  notes?: string;
}
export interface DeceasedDonorOrganRetrievalSurgery {
  _id?: ObjectId;
  deceased_donor?: boolean;
  retrieving_hospital_id?: { $oid?: string };
  primary_testing_hospital_id?: { $oid?: string };
  retrieval_start_date?: string;
  retrieval_end_date?: string;
  insitu?: boolean;
  insitu_left_perfusion_date?: string;
  insitu_left_clamp_time?: string;
  insitu_left_flush_time?: string;
  insitu_left_warm_isch_time?: string;
  exvivo_right_perfusion_date?: string;
  exvivo_right_clamp_time?: string;
  exvivo_right_flush_time?: string;
  exvivo_right_warm_isch_time?: string;
  life_support_withdrawal_date?: string|null;
  donor_blood_pressure?: DeceasedDonorBloodPressure[];
  medications?: DeceasedDonorMedication[];
  cross_clamp_date?: string;
}
export interface DeceasedDonorMedication {
  medication_category_code?: number;
  medication_type_code?: number;
  date_medication_given?: string;
  measurement_unit_code?: number;
  dosage?: number;
  comments?: string;
}
export interface DeceasedDonorBloodPressure {
  measured_date?: string;
  systolic_blood_pressure?: number;
  diastolic_blood_pressure?: number;
  heart_rate?: number;
}
export interface DeceasedDonorAttachment {
  _id?: ObjectId;
  uuid?: string;
  category_code?: number;
  created_at?: string;
  updated_at?: string;
  original_filename?: string;
  description?: string;
  mime_type?: string;
  created_by?: string;
  updated_by?: string;
  deleted?: boolean;
  national_attachment_id?: number;
  from_ctr?: boolean;
  ctr_status?: string;
}

export interface DonorSignificantEvent {
  // Fields from schema
  _id: ObjectId;
  donor_id: ObjectId;
  event_date: string;
  created_at: string;
  created_by?: string;
  // Fields included by web app API
  user?: {
    user_id: string|null;
    first_name: string|null;
    last_name: string|null;
  };
}

// Values of 'changed_field' in events that are treated as significant
export const DonorSignificantAttributes = [
  // ABO i.e. Blood Type
  'blood_type',
  'blood_sub_type',
  'blood_rh',
  // Donor indicators
  'ecd',
  'double_kidney',
  'exd',
  'exceptional_distribution',
  'exd_reason_codes',
  'exd_reason_details_travel',
  'exd_reason_details_other',
  'exd_reason_details_transmission',
  // NDD / DCD i.e. Donor death
  'neurological_death',
  // Donor observations
  'height',
  'weight',
  // Donor patient profile
  'age',
  // Serology / Virology
  'code',
  'initial_result',
  'repeat_result',
  'confirm_comparative_result',
  // HLA results
  'invalidated_date',
  'typing_incomplete',
  'epitopes',
  'antigens',
];

export interface DonorSignificantEventAttributeChanged extends DonorSignificantEvent {
  updated?: boolean;
  entity: string;
  entity_id: ObjectId;
  changed_fields?: SignificantEventAttributeEntry[];
}

export interface SignificantEventAttributeEntry {
  changed_field?: string;
  path: string;
  value: any;
}

export interface NowListItem {
  id: string;
  acceptance_criteria: AcceptanceCriteria;
  age: Age;
  blood_group: string;
  blood_group_label_en: string;
  height: number;
  hsx_eligible: boolean;
  high_status: boolean;
  in_utero: boolean;
  last_updated: string;
  list_date: string;
  medical_status_change_date: string;
  national_recipient_id: string;
  urgent: string;
  opo: Opo;
  organ: string;
  organ_cpra: number;
  organ_id: number;
  organ_code: string;
  organ_medical_status: string;
  organ_request_status: string;
  province: string;
  local_recipient_id: string;
  transplant_type: string;
  weight: number;
}

export interface FacilityType {
  id: string;
  biz_ref: string;
}
export interface Opo {
  id: string;
  facility_name: string;
  facility_type: FacilityType;
  facility_code: string;
}

export interface AcceptanceCriteria {
  id: string;
  accept_dcd: boolean;
  accept_hep_b_core_ab: boolean;
  accept_hep_c: boolean;
  accept_incompatible_blood_groups: boolean;
  cross_match_required: boolean;
  local_donor_only: boolean;
  organ_request_id: string;
  recipient_id: string;
}

export interface Age {
  days: number;
  negative: boolean;
  weeks: number;
  years: number;
}

export interface PromiseResult {
  status?: string;
  value?: OrganDonationResponseType;
}

export interface OrganDonationResponseType {
  data?: {
    organ_donation?: DeceasedDonorOrganDonations;
  };
  status: number;
}
